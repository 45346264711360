/** @jsx jsx */
import { jsx, Flex, Themed } from 'theme-ui';
import React, { memo, useMemo, useState, ReactElement } from 'react';
import NewsroomGrid from './NewsroomGrid';
import NewsroomFilters from './NewsroomFiltering';
import { NoSearchResults } from '@sprinklr/shared-lib';
import { Link } from '@sprinklr/shared-lib/components/link';
import { useI18next, Trans } from 'gatsby-plugin-react-i18next';

type NewsroomResultsProps = {
  data: any;
  type?: string; //to use as a pre-set filter for newsroom search
};

const NewsroomResults: React.FC<NewsroomResultsProps> = memo(
  ({ data, type }): ReactElement => {
    const { t } = useI18next();
    const [originalData] = useState(data);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [yearFilter, setYearFilter] = useState<number>(-1);
    const [typeFilter, setTypeFilter] = useState<string>(type || 'ALL');
    const [resultsCopy, setResultsCopy] = useState({
      headline: t('All Sprinklr News'),
      match: t('news articles'),
    });
    const [filteredData, setFilteredData] = useState([]);

    const uniqueYears = new Set(
      originalData.map(item => Number(item?.publishedOn?.slice(0, 4))),
    );

    useMemo(() => {
      let accumulatedFilteredData = [];

      setYearsOptions(
        uniqueYears.size > 0 &&
          [{ id: -1, label: t('All Years') }].concat(
            [...uniqueYears]
              .sort(function (a, b) {
                return b - a;
              })
              .map(year => ({ id: year, label: year })),
          ),
      );

      //filter by type
      if (typeFilter === 'MEDIA_COVERAGE') {
        accumulatedFilteredData = originalData?.filter(
          item => item.type === 'FEATURED_NEWS',
        );
      } else if (typeFilter === 'PRESS_RELEASES') {
        accumulatedFilteredData = originalData?.filter(
          item => item.type === 'PRESS_RELEASE',
        );
      } else accumulatedFilteredData = originalData;

      //filter by year
      if (yearFilter > 0) {
        accumulatedFilteredData = accumulatedFilteredData?.filter(
          item => Number(item?.publishedOn?.slice(0, 4)) === yearFilter,
        );
      }

      //update resultsCopy
      typeFilter === 'PRESS_RELEASES'
        ? setResultsCopy({
            headline: t('Press Releases'),
            match: t('press releases'),
          })
        : typeFilter === 'MEDIA_COVERAGE'
        ? setResultsCopy({
            headline: t('Media Coverage'),
            match: t('media mentions'),
          })
        : setResultsCopy({
            headline: t('All Sprinklr News'),
            match: t('news articles'),
          });

      //set filtered data
      setFilteredData(accumulatedFilteredData);
    }, [typeFilter, yearFilter]);

    return (
      <Flex
        sx={{
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          pb: [5, null, 7],
        }}
      >
        <Themed.h1 sx={{ mb: [3, 5] }}>{resultsCopy.headline}</Themed.h1>
        <Flex
          sx={{
            pt: [null, null, 4],
            pb: [3, 4, 5],
            width: '100%',
            flexDirection: ['column', 'row'],
            justifyContent: 'space-between',
            flexFlow: [null, 'row-reverse'],
          }}
        >
          <NewsroomFilters
            setTypeFilter={setTypeFilter}
            setYearFilter={setYearFilter}
            yearsOptions={yearsOptions}
          />
          <Themed.h4 sx={{ justifySelf: 'flex-start' }}>
            {(yearFilter > 0 || typeFilter !== 'ALL') &&
              t('searchFoundMultiplesMessage', {
                count: filteredData?.length,
                types: resultsCopy.match,
              })}
          </Themed.h4>
        </Flex>
        {filteredData.length > 0 ? (
          <NewsroomGrid
            data={filteredData}
            isFixedLength={false}
            limit={12}
            cardVariant={'plain'}
          />
        ) : (
          <NoSearchResults
            searchHeadlineCopy={t('searchNoResultsHeadline', {
              matches: resultsCopy.match,
              year: yearFilter,
            })}
            suggestionKey={'searchNoResultsSuggestionCopy'}
            suggestionLink={'/newsroom'}
            suggestionLinkTextKey={'searchNoResultsSuggestionLinkText'}
          />
        )}
      </Flex>
    );
  },
);

export default NewsroomResults;
