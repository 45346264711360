/** @jsx jsx */
import { jsx, Flex, Box, Themed } from 'theme-ui';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Card, Link } from '@sprinklr/shared-lib';
import ArrowIcon from '@sprinklr/shared-lib/assets/svgs/arrow.svg';
import setTextTruncate from '@sprinklr/shared-lib/utils/setTextTruncate';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { getSiteDomain } from '../../../../../../utils/getSiteDomain';
import { resolveLocale } from '@sprinklr/shared-lib/utils';

type NewsroomCardProps = {
  newsItem: any;
  variant?: 'shadow' | 'plain' | 'media';
  display?: string;
};

const NewsroomCard: React.FC<NewsroomCardProps> = ({
  newsItem,
  variant = 'shadow',
  display,
}) => {
  const domain = getSiteDomain();
  const { t } = useI18next();
  const localePrefix = newsItem.translations?.languagesToRenderIn?.some(
    translation => translation.includes(newsItem.nodeLocale),
  )
    ? newsItem.nodeLocale === 'en-US'
      ? resolveLocale(newsItem.nodeLocale)
      : `${resolveLocale(newsItem.nodeLocale)}/`
    : '';

  const redirectUrl = newsItem.externalLink
    ? newsItem.externalLink
    : `/${localePrefix}newsroom/${newsItem.slug}/`;

  const styles = {
    shadow: {
      card: {
        flexDirection: 'column',
      },
      img: {
        borderRadius: '10px',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
      },
      body: {
        px: [3, 4],
      },
      title: {},
      p: {},
      logo: {},
    },

    plain: {
      card: {
        boxShadow: 'none',
        flexDirection: 'column',
      },
      img: {
        borderRadius: ['10px'],
      },
      body: {
        px: 0,
      },
      title: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '32px',
        mb: ['10px'],
      },
      p: {
        color: '#646470',
        fontSize: ['14px', '16px'],
      },
      logo: {
        border: '1px solid #ddd',
      },
    },

    media: {
      card: {
        flexDirection: ['column', 'row'],
        boxShadow: 'none',
      },
      img: {
        borderRadius: [3, 3, '8px'],
        height: ['200px', '160px'],
        width: ['100%', '160px'],
        order: [0, 2],
        mb: [3, 0],
      },
      body: {
        pl: 0,
        pr: [3, 4],
        pt: [0],
      },
      title: {
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '32px',
        m: [0],
      },
      p: {
        color: '#383F3B',
        fontSize: 2,
        lineHeight: 1.5,
      },
      logo: {},
    },
  };

  const variantStyle: any = styles[variant] || styles['shadow'];

  // Format date to display nicely
  const date = new Date(newsItem.publishedOn);
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  newsItem.displayDate = dateTimeFormat.format(date);

  return (
    <Card
      key={newsItem.slug}
      cardSx={{
        width: '100%',
        height: '100%',
        display: display === 'none' ? 'none' : 'flex',
        p: [0, 0, 0],
        ...variantStyle.card,
      }}
    >
      <GatsbyImage
        image={newsItem?.image?.gatsbyImageData}
        sx={{
          flex: 'none',
          zIndex: '1',
          aspectRatio: '16/9',
          '@supports not (aspect-ratio: 16 / 9)': {
            height: '200px',
          },
          ...variantStyle.img,
        }}
      />
      <Flex
        sx={{
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
          pt: '20px',
          pb: 4,
          ...variantStyle.body,
        }}
      >
        <Flex sx={{ flex: 1, flexDirection: 'column', overflow: 'hidden' }}>
          {variant === 'media' ? (
            <GatsbyImage
              sx={{
                mb: ['-10px'],
                img: {
                  objectFit: 'contain !important',
                  objectPosition: 'center left !important',
                },
                ...variantStyle.logo,
              }}
              style={{ width: 'auto', height: '40px' }}
              image={newsItem.authorImage.gatsbyImageData}
            />
          ) : null}
          <Link
            styledLinkSx={{
              border: 0,
              '*': {
                textUnderlinePosition: 'under',
                textDecoration: 'underline',
                textDecorationColor: 'transparent',
                transition: '.2s text-decoration-color',
              },
              ':hover': {
                border: 0,
                '*': {
                  textDecorationColor: '#000',
                  transition: '.2s text-decoration-color',
                },
              },
            }}
            href={redirectUrl}
          >
            <Themed.h6
              sx={{
                fontWeight: 'bold',
                ...variantStyle.title,
                ...setTextTruncate('2'),
              }}
            >
              {newsItem.title}
            </Themed.h6>
          </Link>
          {variant !== 'media' ? (
            <Themed.p
              sx={{
                color: 'textMedium',
                ...variantStyle.p,
                ...setTextTruncate('4'),
              }}
            >
              {newsItem?.description?.description}
            </Themed.p>
          ) : null}
        </Flex>
        <Box>
          <Flex
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              mt: [2],
              mb: [1],
              mx: 1,
              pb: 1,
            }}
          >
            {variant !== 'media' ? (
              <GatsbyImage
                sx={{
                  borderRadius: '50%',
                  mr: [3],
                  border: '1px solid #ddd',
                }}
                style={{ width: '50px', minWidth: '50px', height: '50px' }}
                image={newsItem.authorImage.gatsbyImageData}
              />
            ) : null}

            <Box>
              {variant !== 'media' ? (
                <Box as="p" sx={{ mb: 0, fontSize: 2 }}>
                  {newsItem.authorName}
                </Box>
              ) : null}
              <Box as="p" sx={{ mb: 0, fontSize: 2 }}>
                {newsItem.displayDate} &bull; {newsItem.minutesToRead} min read
              </Box>
            </Box>
          </Flex>
          <Link
            styledLinkSx={{
              textDecoration: 'none',
              display: 'inline-block',
              outline: 'none',
            }}
            href={redirectUrl}
            isOpenNewTab={newsItem.externalLink ? true : false}
          >
            {t('Read Article')}
            <Box
              sx={{
                display: 'inline',
                verticalAlign: 'middle',
              }}
            >
              <ArrowIcon
                sx={{
                  ml: '8px',
                  height: ['12px', '14px'],
                }}
              />
            </Box>
          </Link>
        </Box>
      </Flex>
    </Card>
  );
};

export default NewsroomCard;
