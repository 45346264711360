/** @jsx jsx */
import { jsx, Grid, Box } from 'theme-ui';
import React, { ReactElement, useState } from 'react';
import NewsroomCard from './NewsroomCard';
import { Button, CardGrid } from '@sprinklr/shared-lib';
import { useI18next } from 'gatsby-plugin-react-i18next';

type NewsroomGridProps = {
  data: any;
  isFixedLength: boolean;
  limit?: number;
  cardVariant: 'shadow' | 'plain' | 'media';
};

const NewsroomGrid: React.FC<NewsroomGridProps> = ({
  data,
  isFixedLength,
  limit,
  cardVariant,
}): ReactElement => {
  const [upperBound, setUpperBound] = useState<number>(limit);
  const cardOrientation = cardVariant === 'media' ? 'horizontal' : 'vertical';
  const { t } = useI18next();

  const cards = data.map((item, index) => {
    const display = index + 1 > upperBound ? 'none' : '';
    return (
      <NewsroomCard
        key={index}
        newsItem={item}
        variant={cardVariant}
        display={display}
      />
    );
  });

  const handleShowMore = () => {
    if (upperBound + limit >= data.length) setUpperBound(data.length);
    else setUpperBound(upperBound + limit);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          mb: [4, 5],
        }}
      >
        <CardGrid cardVariant={cardOrientation}>{cards}</CardGrid>
      </Box>
      {isFixedLength ||
        (upperBound < data.length && (
          <Button type={'primary'} onClick={handleShowMore}>
            {t('Show More')}
          </Button>
        ))}
    </React.Fragment>
  );
};
export default NewsroomGrid;
