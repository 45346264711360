import React from 'react';
import { Box } from 'theme-ui';
import { Container } from '@sprinklr/shared-lib';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import NoLocaleContentTemplate from '../noLocaleContent/NoLocaleContent';
import NewsroomResults from './newsroom/components/NewsroomResult';

const AllNewsTemplate: React.FC<{
  padding: ContainerPadding;
  localizedNews: any;
}> = ({ padding, localizedNews }) => {
  const [pt, pb] = useContainerPadding(padding);

  return !localizedNews?.length ? (
    <Box>
      <NoLocaleContentTemplate padding={{ top: 'LARGE', bottom: 'LARGE' }} />
    </Box>
  ) : (
    <Container containerSx={{ pt, pb }}>
      <NewsroomResults data={localizedNews} />
    </Container>
  );
};

export default AllNewsTemplate;
