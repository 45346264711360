import { Box, Flex, Themed } from 'theme-ui';
import React, { ReactElement, useState } from 'react';
import {
  Button,
  Select,
  ConditionallyAppliedWrapper,
} from '@sprinklr/shared-lib';
import FilterIcon from '@sprinklr/shared-lib/assets/svgs/filter.svg';
import { SearchModal } from '@sprinklr/shared-lib/templates/headerTemplate';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type NewsroomFiltersProps = {
  setTypeFilter: any;
  setYearFilter: any;
  yearsOptions?: { id: string; label: string }[];
};

const FormContext = React.createContext(null);

const NewsroomFilters: React.FC<NewsroomFiltersProps> = ({
  setTypeFilter,
  setYearFilter,
  yearsOptions,
}): ReactElement => {
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();
  const bpIndex = useBreakpointIndex({ defaultIndex: 0 });
  const isMobileBP = bpIndex === 0;
  const [isFilterModalOpen, setFilterModal] = useState<boolean>(false);

  const filterSx = { width: ['100%', '160px', '184px'] };

  const onFilterClick = () => setFilterModal(!isFilterModalOpen);

  const resetFilters = () => {
    onFilterChange('ALL', 'type');
    onFilterChange(-1, 'year');
  };

  const onFilterChange = (filterCriteria, filterType) => {
    if (filterType === 'year') {
      const selectedYear = Number(filterCriteria) || -1;
      setYearFilter(selectedYear);
    }

    if (filterType === 'type') {
      const selectedType = filterCriteria || 'ALL';
      setTypeFilter(selectedType);
    }
  };
  return (
    <FormContext.Provider value={{ formData }}>
      <Flex sx={{ flexDirection: ['column', 'row'] }}>
        {isMobileBP && (
          <Button
            type={'secondary'}
            onClick={onFilterClick}
            buttonSx={{ border: 0, my: '10px', marginLeft: 'auto' }}
          >
            <FilterIcon sx={{ pr: [2] }} />
            <span sx={{ pl: [2] }}>{'Filter'}</span>
          </Button>
        )}
        <ConditionallyAppliedWrapper
          condition={isMobileBP}
          Wrapper={children => (
            <SearchModal
              isModalOpen={isFilterModalOpen}
              closeModal={onFilterClick}
            >
              {children}
            </SearchModal>
          )}
        >
          {isMobileBP && <Themed.h4>{'Filters'}</Themed.h4>}
          <Box sx={{ mr: [0, '22px'], mb: [2, 0] }}>
            <Select
              options={[
                { id: 'ALL', label: t('All News') },
                { id: 'PRESS_RELEASES', label: t('Press Releases') },
                { id: 'MEDIA_COVERAGE', label: t('Media Coverage') },
              ]}
              onChange={e => onFilterChange(e.target.value, 'type')}
              selectSx={{ mb: [2, 0], ...filterSx }}
            />
          </Box>
          <Select
            options={yearsOptions}
            onChange={e => onFilterChange(e.target.value, 'year')}
            selectSx={{ ...filterSx }}
          />
          {isMobileBP && (
            <Flex sx={{ mt: [4] }}>
              <Button onClick={onFilterClick}>{'apply changes'}</Button>
              <Button
                type={'secondary'}
                onClick={resetFilters}
                buttonSx={{ border: 0 }}
              >
                {'reset'}
              </Button>
            </Flex>
          )}
        </ConditionallyAppliedWrapper>
      </Flex>
    </FormContext.Provider>
  );
};

export default NewsroomFilters;
