import { isWindowDefined } from '@sprinklr/shared-lib/utils/isWindowDefined';

export const getSiteDomain = (): string => {
  let domain;
  if (isWindowDefined() && process.env.GATSBY_ENV !== 'production') {
    domain = window.location.origin;
  } else {
    switch (process.env.GATSBY_ENV) {
      case 'production':
        domain = 'https://www.sprinklr.com';
        break;
      case 'staging':
        domain = 'https://staging.sprinklr.com';
        break;
      case 'development':
        domain = 'https://dy3shwc4ht83r.cloudfront.net';
        break;
    }
  }
  return domain;
};
